<script lang="ts">
	import { getContext } from 'svelte';
	import { NavLi, Dropdown, DropdownItem } from 'flowbite-svelte';
	import { fade } from 'svelte/transition';
	import { FontAwesomeIcon } from '@fortawesome/svelte-fontawesome';
	import { faChevronDown } from '@awesome.me/kit-065f90d5b1/icons/classic/solid';

	export let navItem;
	export let trigger = 'hover';
	export let open = false;
	export let active = false;
	// export let i

	let triggerElement;
	let hasDropdown = navItem.navItems?.items?.length > 0;

	let classParts = {
		linkTextHover: 'hover:text-tee-red-default'
	};

	let classes = {
		link: `font-headline text-[20px] ${classParts.linkTextHover}`,
		linkActive: 'text-tee-red-default',
		linkNonActive: '',
		dropdownContainer: '!m-0',
		dropdownUl: `static z-auto mt-5 overflow-auto bg-white rounded-md shadow-lg flex flex-col p-2 border divide-y divide-solid divide-slate-100`,
		dropdownLink: `relative z-10 flex items-start space-x-4 w-full p-4 min-w-[200px] group block hover:bg-slate-100 hover:underline ${classParts.linkTextHover}`,
		dropdownLinkActive: 'text-tee-red-default'
	};

	let navItemProps: Record<string, any> = {
		class: classes.link + (hasDropdown ? ' cursor-pointer' : ''),
		activeClass: hasDropdown ? classes.linkNonActive : classes.linkActive,
		nonActiveClass: classes.linkNonActive,
		href: navItem.page?.path,
		active
	};
	$: navItemProps.active = active;
	$: activeUrl = getContext('activeUrl');
	$: {
		if (active) {
			navItemProps['aria-current'] = 'page';
			activeUrl = navItemProps.href;
		}
	}

	let dropdownProps = {
		trigger: trigger || 'hover',
		open: open || false,
		color: 'none',
		shadow: false,
		rounded: false,
		arrow: true,
		offset: 0,
		transition: fade,
		params: { delay: 0, duration: 200 },
		frameClass: classes.dropdownContainer,
		class: classes.dropdownUl
	};
</script>

{#if navItem.page}
	{#if !hasDropdown}
		<NavLi {...navItemProps}>{navItem.label}</NavLi>
	{:else}
		<NavLi {...navItemProps} bind:this={triggerElement}>
			<span class="flex gap-2 whitespace-nowrap">{navItem.label}<FontAwesomeIcon icon={faChevronDown} size="xs" /></span>
		</NavLi>
		<Dropdown {...dropdownProps} triggeredBy={triggerElement}>
			{#each navItem.navItems.items as subNavItem, e (subNavItem)}
				{#if subNavItem.page}
					<DropdownItem href={subNavItem.page.path} defaultClass={classes.dropdownLink + (false ? ' ' + classes.dropdownLinkActive : '')}>{subNavItem.label}</DropdownItem>
				{/if}
			{/each}
		</Dropdown>
	{/if}
{/if}
