<script lang="ts">
	import Modal from '$lib/components/Modal.svelte';
	import { PageTransition } from '$lib/components/pageTransition';
	import { ScheduleForm } from '$lib/forms/schedule';
	import { MainFooter, MainHeader } from '$lib/sections/layouts/main';
	import type { LayoutData } from './$houdini';
	import { CldImage } from 'svelte-cloudinary';
	import { getExpiry } from '$lib/functions/utils';
	import { terms, pageStatus } from '$stores';

	export let data: LayoutData;

	export let expiry = getExpiry({ format: 'MMMM YYYY' });
</script>

<MainHeader />

<main class="flex flex-col grow bg-white z-20">
	{#if $pageStatus.load}
		<PageTransition path={data.currentPath}>
			<slot />
		</PageTransition>
	{/if}
</main>

<MainFooter />

<Modal key="form" value="schedule" outsideclose={false}>
	<div class="flex flex-col gap-8 mx-5">
		<h2 class="font-headline text-4xl lg:text-6xl text-black">{terms.ctas.form}</h2>
		<ScheduleForm />
	</div>
</Modal>

<Modal key="campaign" value="ilovethomasedison">
	<CldImage loading="eager" class="object-cover" width="848" height="248" alt="I Love Thomas Edison campaign" src={'campain/ilovethomasedison'} />
	<div class="flex flex-col items-center px-8 pb-8">
		<div class="text-2xl font-medium flex gap-4 justify-center text-center mb-7 leading-8 text-tee-blue-default">Honest, reliable electricians. What's not to love?<br />And we're loving you right back with</div>
		<div class="border p-8 mb-8 rounded border-black/90 text-center text-tee-blue-default">
			<p class="font-headline text-7xl text-tee-red-default font-bold mb-2.5">$50 off</p>
			<p class="font-headline text-3xl font-semibold mb-5">ANY job in {expiry}</p>
			<p class="text-xl">New clients only</p>
		</div>
		<a href="/coupon/ilovethomasedison" target="_blank" class="text-lg w-full flex place-items-center place-content-center font-headline font-semibold focus:ring-4 duration-300 text-center space-x-2 rounded py-2.5 px-4 text-white bg-tee-red-default hover:bg-tee-red-s20 focus:ring-blue-300">Claim your offer now!</a>
	</div>
</Modal>
