<script lang="ts">
	import { Modal } from 'flowbite-svelte';
	import { queryParam, ssp } from 'sveltekit-search-params';

	export let key = 'modal';
	export let value: string;

	const paramStore = queryParam(key, ssp.string(), {
		pushHistory: true
	});

	export let outsideclose = true;

	$: open = Boolean($paramStore && $paramStore === value);
</script>

<Modal bind:open on:close={() => ($paramStore = null)} {outsideclose}>
	<slot />
</Modal>
