<script>
	import { SidebarItem, SidebarDropdownItem, SidebarDropdownWrapper } from 'flowbite-svelte';
	import { FontAwesomeIcon } from '@fortawesome/svelte-fontawesome';
	import { faChevronDown, faChevronUp } from '@awesome.me/kit-065f90d5b1/icons/classic/solid';

	export let navItem;
	export let active = false;
</script>

{#if navItem.page}
	{#if navItem.navItems?.items?.length > 0}
		<SidebarDropdownWrapper label={navItem.label} href={navItem.page.path} {active}>
			<svelte:fragment slot="arrowup">
				<FontAwesomeIcon icon={faChevronUp} size="xs" />
			</svelte:fragment>
			<svelte:fragment slot="arrowdown">
				<FontAwesomeIcon icon={faChevronDown} size="xs" />
			</svelte:fragment>
			{#each navItem.navItems.items as subNavItem, e (subNavItem)}
				<SidebarDropdownItem label="Products" href={subNavItem.label} />
			{/each}
		</SidebarDropdownWrapper>
	{:else}
		<SidebarItem label={navItem.label} href={navItem.page.path} {active} />
	{/if}
{/if}
