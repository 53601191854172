<script lang="ts">
	import { browser } from '$app/environment';
	import { onMount } from 'svelte';
	import { page } from '$app/stores';
	import { TopNavItem, MobileTopNavDrawer } from '$lib/sections/layouts/main';
	import { ScheduleButton, CallButton } from '$lib/sections/ctas';
	import { Navbar, NavUl } from 'flowbite-svelte';
	import { Button } from '$lib/components/forms/v2';
	import { headerHeight } from '$stores';

	import { FontAwesomeIcon } from '@fortawesome/svelte-fontawesome';
	import { faPhone, faBars } from '@awesome.me/kit-065f90d5b1/icons/classic/solid';

	const { globalInfo } = $page.data;

	let headerEl: HTMLElement;
	let isMobileMenuHidden = true;

	let scrollY: number;
	let prevScrollY: number;

	const offset = 100;

	onMount(() => {
		if (browser) prevScrollY = scrollY;

		$headerHeight = headerEl?.offsetHeight;
	});

	$: firstSlug = '/' + $page.url.pathname.split('/')[1];
</script>

<svelte:window
	bind:scrollY
	on:scroll={() => {
		if (Math.abs(prevScrollY - scrollY) > offset) prevScrollY = scrollY;
	}}
/>
<header id="page-header" bind:this={headerEl} class="z-30 flex flex-col md:flex-row justify-between bg-white/95 top-0 sticky">
	<section class="flex w-full md:w-fit items-center justify-between px-8 py-1.5 sm:py-3.5">
		<Button aria-label="Mobile Menu" class="block p-4 aspect-square md:hidden hover:text-tee-blue-s20" on:click={() => (isMobileMenuHidden = false)}><FontAwesomeIcon icon={faBars} size="xl" /></Button>
		<a href="/" class="block max-w-[145px] md:max-w-[211px]"><img fetchpriority="high" loading="eager" src="//res.cloudinary.com/thomasedison/image/upload/c_scale,h_109,w_211/q_auto/f_svg/general/logo-w-slogan-blue" alt="Thomas Edison Electric logo" width="211px" height="108.78px" /></a>
		<div class="md:hidden">
			<a href={globalInfo.settings.phoneCallCenterHref} aria-label="Phone Number" class="block p-3 aspect-square hover:text-tee-blue-s20"><FontAwesomeIcon icon={faPhone} size="xl" fixedWidth /></a>
		</div>
	</section>
	<section class="hidden md:flex md:flex-col md:items-end">
		<div id="header-top" class="bg-tee-red-default w-fit px-8">
			<div class="flex items-center py-1 space-x-7 lg:space-x-10 text-white text-sm min-[1131px]:text-base">
				<p class="italic shrink">Your 24/7 Emergency Service Electrician</p>
				<div class="shrink flex space-x-7 items-center">
					<a href={globalInfo.settings.phoneCallCenterHref} class="flex space-x-0.5 items-center">
						<span class="mr-2"><FontAwesomeIcon icon={faPhone} fixedWidth /></span>
						<span class="text-slate-100 font-medium sr-only md:not-sr-only">{globalInfo.settings.phoneCallCenter}</span>
					</a>
					<!-- <button class="flex space-x-0.5 items-center">
						<span class="mr-2"><FontAwesomeIcon icon={faMessages} fixedWidth /></span>
						<span class="text-slate-100 font-medium sr-only md:not-sr-only">Live Chat</span>
					</button> -->
				</div>
			</div>
		</div>
		<div id="header-bottom" class="flex flex-row flex-wrap py-2.5 h-full justify-end">
			<div class="flex items-center shrink">
				<Navbar aria-label="Main" let:hidden fluid={true} class="px-4 sm:px-4">
					<NavUl {hidden} activeUrl={firstSlug} class="grid grid-flow-col auto-cols-max" activeClass="text-white bg-tee-blue-default md:bg-transparent md:text-tee-blue-default md:dark:text-white dark:bg-tee-blue-default md:dark:bg-transparent">
						{#each globalInfo.topNav.navItems.items as navItem, i (navItem)}
							<!-- If a nav item is in draft mode, it will still be in the array as null -->
							{#if navItem?.page}
								<TopNavItem {navItem} trigger="hover" active={firstSlug !== '/' && navItem.page.path.indexOf(firstSlug) === 0} open={false} />
							{/if}
						{/each}
					</NavUl>
				</Navbar>
			</div>
			<div class="flex flex-auto justify-end items-center px-8">
				<ScheduleButton theme="light" />
				<!-- <CallButton theme="blue" /> -->
			</div>
		</div>
	</section>
</header>
<MobileTopNavDrawer bind:hidden={isMobileMenuHidden} />
