<script>
	import { page } from '$app/stores';
	import { FontAwesomeIcon } from '@fortawesome/svelte-fontawesome';

	const { globalInfo } = $page.data;
</script>

<footer class="flex flex-col flex-none w-full mx-auto text-slate-50 z-10">
	<div class="flex flex-col lg:flex-row items-center lg:items-start lg:justify-between space-y-8 lg:space-y-0 bg-tee-blue-s40 p-8">
		<div class="flex space-x-2 pt-2 lg:order-2">
			{#each globalInfo.settings.social.items as item, i}
				<a href={item.url} target="_blank" aria-label={item.name}>
					{#if !item.useImageAsIcon}
						<FontAwesomeIcon icon={item.icon} size="2x" />
					{:else}
						<icon class="w-14"><img loading="lazy" src={item.icon} alt={item.name} width="60.85px" height="42.33px" /></icon>
					{/if}
				</a>
			{/each}
		</div>
		<div class="flex flex-col items-center space-y-1 lg:order-1">
			<div class="flex flex-wrap gap-2 place-content-center place-items-center">
				{#each globalInfo.settings.paymentMethods.items as item, i}
					{#if !item.useImageAsIcon}
						<FontAwesomeIcon icon={item.icon} size="2x" />
					{:else}
						<icon class="w-14"><img loading="lazy" src={item.icon} alt={item.name} width="60.85px" height="42.33px" /></icon>
					{/if}
				{/each}
			</div>
			<p class="font-light text-base">Financing Available</p>
		</div>
	</div>
	<div class="bg-tee-blue-s60 py-2">
		<div class="flex flex-col space-y-2 px-8 items-center md:flex-row md:space-y-0 md:justify-between">
			<a class="text-sm text-center opacity-70" href="/privacy-policy">Privacy Policy</a>
			<p class="text-sm text-center opacity-70">&copy; {new Date().getFullYear()} Thomas Edison Electric. All rights reserved.</p>
		</div>
	</div>
</footer>
