<script>
	import { page } from '$app/stores';
	import { terms } from '$stores';
	import { sineIn } from 'svelte/easing';
	import { Drawer, CloseButton, Sidebar, SidebarGroup, SidebarItem, SidebarWrapper } from 'flowbite-svelte';
	import { MobileTopNavItem } from '$lib/sections/layouts/main';

	import { FontAwesomeIcon } from '@fortawesome/svelte-fontawesome';
	import { faTag, faCalendarClock } from '@awesome.me/kit-065f90d5b1/icons/classic/regular';
	import { faPhone } from '@awesome.me/kit-065f90d5b1/icons/classic/solid';

	const { globalInfo } = $page.data;

	export let hidden = true;

	$: firstSlug = '/' + $page.url.pathname.split('/')[1];
	$: href = $page.url.href;

	$: {
		if (href) hidden = true;
	}

	let transitionParams = {
		x: -320,
		duration: 200,
		easing: sineIn
	};
</script>

<Drawer id="mobile_menu" bind:hidden transitionType="fly" {transitionParams}>
	<div class="flex items-center">
		<h5 id="drawer-navigation-label-3" class="text-base font-semibold text-gray-500 uppercase dark:text-gray-400">Menu</h5>
		<CloseButton on:click={() => (hidden = true)} class="mb-4 dark:text-white" />
	</div>
	<Sidebar>
		<SidebarWrapper divClass="overflow-y-auto py-4 px-3 rounded dark:bg-gray-800">
			<SidebarGroup>
				<MobileTopNavItem navItem={{ label: 'Home', page: { path: '/' } }} active={firstSlug === '/'} />
				{#each globalInfo.topNav.navItems.items as navItem}
					<MobileTopNavItem {navItem} active={firstSlug === navItem.page.path} />
				{/each}
			</SidebarGroup>
			<SidebarGroup border>
				<SidebarItem label={terms.ctas.form} href="/schedule">
					<svelte:fragment slot="icon">
						<FontAwesomeIcon icon={faCalendarClock} size="1x" />
					</svelte:fragment>
				</SidebarItem>
				<SidebarItem label={terms.ctas.phone} href={globalInfo.settings.phoneCallCenterHref}>
					<svelte:fragment slot="icon">
						<FontAwesomeIcon icon={faPhone} size="1x" />
					</svelte:fragment>
				</SidebarItem>
				<SidebarItem label={terms.ctas.coupon} href="/coupon/100">
					<svelte:fragment slot="icon">
						<FontAwesomeIcon icon={faTag} size="1x" />
					</svelte:fragment>
				</SidebarItem>
			</SidebarGroup>
		</SidebarWrapper>
	</Sidebar>
</Drawer>
